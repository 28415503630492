import React from "react";
import "./termsofservice.scss";
const TermsOfService = () => {
  return (
    <div className="termsofservice-all-content-alignment">
      <div className="new-container">
        <h1>Terms of Service </h1>
        <p>Effective Date: 15 Jan, 2025 </p>
        <p>
          Welcome to Alladdin! These Terms of Service ("Terms") govern your use of the Alladdin platform, including our website, Chrome extension, and related services (collectively, "Services"). By accessing or using Alladdin, you agree to these
          Terms. If you do not agree, you may not use our Services.
        </p>
        <h2>1. Acceptance of Terms</h2>
        <p>By creating an account, installing our extension, or using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>

        <h2>2. Eligibility</h2>
        <p>You must be at least 18 years old to use Alladdin. By using our Services, you represent and warrant that you have the legal capacity to enter into this agreement.</p>
        <h2>3. Account Responsibilities</h2>
        <ul>
          <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
          <li>You agree to provide accurate and complete information when creating your account.</li>
          <li>You are solely responsible for all activities under your account.</li>
        </ul>
        <h2>4. Use of Services</h2>
        <ul>
          <li>
            <strong>Permitted Use: </strong> Alladdin is intended for personal or small business use to manage subscriptions.
          </li>
          <li>
            <strong>Prohibited Use:</strong> You may not misuse our Services, including attempting to hack, interfere with, or reverse-engineer any part of the platform.
          </li>
        </ul>
        <h2>5. Subscription Tracking and Virtual Cards</h2>
        <ul>
          <li>
            <strong>Tracking:</strong> Alladdin helps track your subscriptions, payment schedules, and usage analytics.
          </li>
          <li>
            <strong>Virtual Cards: </strong> We issue virtual cards through integrated partners for subscription management. By using this feature, you agree to our partner's terms for virtual card issuance.
          </li>
          <li>
            <strong>Accuracy:</strong> While we strive to provide accurate data, Alladdin is not liable for any errors, missed cancellations, or subscription-related disputes.
          </li>
        </ul>

        <h2>6. Payments</h2>
        <ul>
          <li>
            <strong>Free and Paid Plans:</strong> Alladdin offers free and premium plans. Details about features and pricing can be found on our website.
          </li>
          <li>
            <strong>Billing:</strong> Paid subscriptions are billed in advance. All fees are non-refundable unless required by law.
          </li>
          <li>
            <strong> Third-Party Services:</strong> Payments through third-party providers (e.g., PayPal) are subject to their terms and policies.
          </li>
        </ul>

        <h2>7. Cancellation and Termination</h2>
        <ul>
          <li>You may cancel your subscription at any time through your account dashboard.</li>
          <li>Alladdin reserves the right to suspend or terminate your account for violations of these Terms.</li>
        </ul>

        <h2>8. Privacy</h2>
        <p>Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your information.</p>

        <h2>9. Intellectual Property</h2>
        <p>Alladdin retains all rights to the platform, including software, logos, and trademarks. You may not copy, distribute, or modify any part of our Services without permission.</p>

        <h2>10. Disclaimers and Limitation of Liability</h2>
        <ul>
          <li>
            <strong>Disclaimers: </strong>Alladdin is provided "as is" without warranties of any kind. We do not guarantee uninterrupted service or accuracy.
          </li>
          <li>
            <strong>Liability: </strong>To the maximum extent permitted by law, Alladdin is not liable for any direct, indirect, or incidental damages arising from your use of the Services.
          </li>
        </ul>

        <h2>11. Indemnification</h2>
        <p>You agree to indemnify and hold harmless Alladdin and its affiliates from any claims, damages, or expenses arising from your use of the Services or violation of these Terms.</p>

        <h2>12. Changes to Terms</h2>
        <p>We may update these Terms from time to time. We will notify you of any material changes by posting updates on our website or through the platform.</p>

        <h2>13. Governing Law and Dispute Resolution</h2>
        <p>These Terms are governed by the laws of Australia. Any disputes will be resolved through binding arbitration or in the courts of Australia.</p>
        <h2> 14. Contact Information</h2>
        <p>If you have questions about these Terms, please contact us at:</p>
        <p>
          <strong>Email:</strong> <a href="mailto:info@alladdinco.com">info@alladdinco.com</a>
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
