import React, { useState } from "react";
import styles from "./features.module.css";
import axios from "axios";
import Appconstants from "../../constants/AppConstants";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";

const FeatureCard = ({ feature }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
  const handleCheckout = (price = 0) => {
    console.log(price, "clickeed");
    // let data = { price };
    if (userData) {
      try {
        setLoading(true);
        // navigate("/https://buy.stripe.com/7sIdQRfTocj3dLa6oo"); // for live");
        // window.location.href = "https://buy.stripe.com/7sIdQRfTocj3dLa6oo"; // for live
        axios
          .post(
            `${Appconstants.baseURL}/subscription/create-checkout-session`,
            { price: price },
            {
              // method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-auth-token": localStorage.getItem("authToken"),
                "ngrok-skip-browser-warning": "69420",
              },
              // mode: "cors",
              // body: JSON.stringify({ price: price }),
            }
          )
          .then((res) => {
            //   if (res.ok) return res.json();
            //   console.log(res);
            //   return res.json().then((json) => Promise.reject(json));
            // })
            // .then(({ session }) => {
            //   console.log("resssssssssssssssssssss222", session)
            //   setLoading(false);
            // window.location = session.url;
            // console.log("ressssssssssssssss", res)
            window.open(res?.data?.payload, "_self");
          })
          .catch((e) => {
            console.log(e.error);
          });
      } catch (error) {
        console.log(error, "error");
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <>
    <div
    style={{
      display: "flex",
      justifyContent:"center",
      flexDirection: "column",
      gap: "18px",
    }}
  >
    <img src="/assets/lockgif.gif" alt="lock" className="lockimage" style={{ width: "140px", height: "140px" }} />
    <h6 className="unsubscribedHeading">
    Start tracking your life now <br />
    </h6>
    <p className="unsubscribedpara">
      Please, Upgrade your plan to <br /> enjoy full benefits
    </p>
  </div>
    <div className={styles.featurecardcontainer}>
      <div className={styles.featurecardHeader}>
        <div className={`${styles.premium_text}`}>
          <p className={`desc_texts `} style={{ color: "#2F2F2F", fontWeight: "800", fontSize: "18px" }}>
            {feature.planTitle}
          </p>
        </div>
       
        {/* <p className={styles.savingbtnText} style={{ color: "#2F2F2F", fontWeight: "500", fontSize: "18px" }}>
        30 Days Free
          </p> */}
      </div>
      {feature.fees ? (
        <div className={styles.pricingSection}>
          <div className={styles.leftsec}>
            {/* <p className={styles.prevfees}>{feature.planprevfeesMonthly}</p> */}
            <p className={styles.newfees}>{feature.planfeesMonthly}</p>
          </div>
           <div className={styles.rightsec}>
            <p className={styles.annualfees}>Billed Monthly</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={styles.featureFooter}>
        <div className={styles.featurenumcontainer}>
          {feature.planFeatures.map((item, id) => (
            <div className="flex items-center gap-[10px] p-2" key={id}>
              <img src={"/assets/righttickicon.svg"} width={10} height={10} />
              <p className={styles.features}>{item.feature}</p>
            </div>
          ))}
        </div>
        <div className={styles.featurebuttonmain}>
          <button
            className={styles.featureBtn}
            onClick={() => handleCheckout(feature.costs)}
            disabled={loading}
            style={{
              opacity: loading ? 0.5 : 1,
              cursor: loading ? "not-allowed" : "pointer",
            }}
          >
            {feature.buttonText}
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default FeatureCard;
