import StarIcon from '../../../svg/StarIcon'
import HalfStarIcon from '../../../svg/HalftSarIcon'

const JoinMember = () => {
  return (
    // <div className='bg-primary pt-[68px] pb-[78px] mobile:py-60'>
    <div className='bg-black300 py-4 mobile:py-4'>
      <div className='max-w-1340 mx-auto px-5 mobile:px-30'>
        <h2 className='text-[40px] mobile:text-[26px] mobile:leading-normal tracking-normal text-white font-bold text-center mb-[18px]'>
        A New Era Calls For New Measures
        </h2>
        {/* <div className='flex items-center gap-2.5 justify-center pb-[22px]'>
            <div className='flex items-center'>
                <StarIcon/>
                <StarIcon/>
                <StarIcon/>
                <StarIcon/>
                <HalfStarIcon />

            </div>
            <p className='text-xl mobile:text-base tracking-normal text-white'>
            4.5 Stars (2k Ratings)
            </p>
        </div>
        <p className='text-lg font-normal mobile:text-base tracking-normal text-white max-w-[636px] mx-auto text-center'>
        We’ve worked hard for the past 5 years saving our members over $3.7 million dollars and counting.
        </p> */}
      </div>
    </div>
  )
}

export default JoinMember
